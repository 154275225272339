export const formatDate = (timestamp) => {
    try {
        const date = new Date(timestamp * 1000);
        const day = date.getDate();
        const monthAbbreviation = new Intl.DateTimeFormat("en-US", {
            month: "short",
        }).format(date);
        const year = date.getFullYear();
        return `${day} ${monthAbbreviation} ${year}`;
    } catch (err) {
        return "";
    }
};