
import { EventScheduler } from "./event-scheduler";

const Management = () => {
    return (
        <>
            <EventScheduler />
        </>
    );
};
export default Management;