import React from 'react';
import Profile from '../../layout/Login/profile';


export const Work = () => {
  return (
    <div>
      <Profile />
    </div>
  );
};
